import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Avatar, FontIcon, ListItem } from 'react-md'
import { connect } from 'react-redux'
import { actions } from 'react-redux-form'
import { replace } from 'react-router-redux'
import {
  arrayMove,
  SortableContainer,
  SortableElement
} from 'react-sortable-hoc'

import { allIcons } from 'constants/UIIcons'
import { fetchItems } from 'redux/modules/crud'
import { matchData } from 'utils/Data'

import EmptyState from '../../EmptyState'

class ManageInformationsSelectedTab extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTabIndex: 1,
      filter: ''
    }
    this.onSortEnd = this.onSortEnd.bind(this)
  }

  componentDidMount() {
    const { dispatch } = this.props
    dispatch(fetchItems('informations'))
  }

  componentWillReceiveProps(nextProps) {
    const { selected_informations } = this.props
    //if selected items have loaded setState, otherwise this is the user changes to the model (ie activeTabIndex is 0)
    if (
      selected_informations &&
      nextProps.selected_informations &&
      selected_informations !== nextProps.selected_informations &&
      this.state.activeTabIndex === 1
    ) {
      const initialTabIndex = nextProps.selected_informations.length > 0 ? 1 : 0
      this.setState({
        activeTabIndex: initialTabIndex
      })
    }
  }

  editItemFromGuidebook(id) {
    const { dispatch, ownerId, ownerSingularName, ownerPluralName } = this.props
    // BA. make sure that we don't reload/refresh the guidebook/template when going off to edit a card
    dispatch(actions.change(`edit_${ownerSingularName}.noReload`, true))
    let url = `/host/${ownerPluralName}/${ownerId}/informations/${id}`
    dispatch(replace(url))
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { selected_informations, dispatch, ownerSingularName } = this.props
    const ordered_recs = arrayMove(selected_informations, oldIndex, newIndex)
    dispatch(
      actions.change(
        'edit_' + ownerSingularName + '.informations',
        ordered_recs
      )
    )
  }

  render() {
    const { active, selected_informations } = this.props

    const SortableItem = SortableElement(({ info }) => {
      let showIcon = null
      if (info.icon) {
        let iconMatch = matchData(
          allIcons,
          'Icons',
          ['field', 'v1_name'],
          info.icon
        )
        if (iconMatch) {
          const IconComponent = iconMatch.icon
          showIcon = <IconComponent fill="#ffffff" />
        } else {
          showIcon = <i className="material-symbols-rounded">{info.icon}</i>
        }
      }
      const secondary = info.title ? info.title : ' '
      let catSpan = null
      if (info.category) {
        const catName =
          info.category.name.charAt(0).toUpperCase() +
          info.category.name.slice(1)
        const catColor = info.category.color
          ? {
              backgroundColor: info.category.color,
              color: '#ffffff',
              padding: '2px 6px',
              borderRadius: '5px'
            }
          : {}
        catSpan = (
          <span className={`rec-list-category ${catName}`} style={catColor}>
            {catName}
          </span>
        )
      }
      let secureSpan = info.secure_access_only ? (
        <span className="item-list-secure">
          <FontIcon>security</FontIcon> Secure
        </span>
      ) : null
      const fullSecondary = (
        <div>
          <span>{secondary}</span>
          <br />
          {secureSpan} {catSpan}
        </div>
      )
      const self = this
      return (
        <ListItem
          key={info.id}
          className="hf-informationslist-item"
          leftAvatar={
            <Avatar
              alt=""
              className="hf-informationslist-item"
              onClick={(e) => {
                self.editItemFromGuidebook(info.id)
              }}
              title="Click to edit"
              icon={showIcon}
            />
          }
          primaryText={info.name}
          secondaryText={fullSecondary}
          rightIcon={<FontIcon>reorder</FontIcon>}
        />
      )
    })

    const SortableList = SortableContainer(({ recs }) => {
      return (
        <div>
          {recs.map((info, index) => (
            <SortableItem key={`item-${index}`} index={index} info={info} />
          ))}
        </div>
      )
    })

    return (
      <div className="md-grid">
        {active &&
          (selected_informations.length > 0 ? (
            <div className="md-cell md-cell--12">
              <SortableList
                recs={selected_informations}
                onSortEnd={this.onSortEnd}
                distance={1}
              />
            </div>
          ) : (
            <EmptyState type="cards" />
          ))}
      </div>
    )
  }
}

ManageInformationsSelectedTab.propTypes = {
  active: PropTypes.bool.isRequired,
  ownerSingularName: PropTypes.string.isRequired,
  ownerPluralName: PropTypes.string.isRequired
}

function mapStateToProps(state, props) {
  const { ownerSingularName } = props
  const edit_item = state['edit_' + ownerSingularName]
  const selected_informations = edit_item.informations || []
  return {
    ownerId: edit_item.id,
    selected_informations: selected_informations
  }
}

export default connect(mapStateToProps)(ManageInformationsSelectedTab)
