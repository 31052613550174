import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { injectIntl, intlShape } from 'react-intl'
import { Avatar, Card, CardTitle, Divider, List, Subheader } from 'react-md'
import { connect } from 'react-redux'

import ListItemModal from 'components/ListItemModal'
import { atLeastPhablet } from 'components/ScreenSize'
import AsHtml from 'components/Translator/AsHtml'
import { checkoutDefaults } from 'constants/SharedPropDefaults'
import { checkoutShape } from 'constants/SharedPropTypes'
import {
  checkoutIcons,
  checkoutItemHeadings,
  checkoutItems,
  checkoutMessages
} from 'constants/UIConstants'
import { allIcons } from 'constants/UIIcons'
import { matchData } from 'utils/Data'
import { translateString } from 'utils/I18nHelper'
import {
  cleanUpTextOnly,
  randomString,
  stringToId,
  transformCustomVariables
} from 'utils/Strings'
import { fieldHasContent } from 'utils/ValidationHelpers'

import PrintSubsection from '../../../PrintView/PrintSubsection'

const propTypes = {
  viewMode: PropTypes.string,
  checkout: checkoutShape,
  guidebook: PropTypes.shape({
    host_user_id: PropTypes.number,
    id: PropTypes.number,
    is_secure: PropTypes.bool,
    key: PropTypes.string,
    token: PropTypes.object
  }),
  custom_color: PropTypes.string,
  intl: intlShape.isRequired
}

const defaultProps = {
  viewMode: 'web',
  checkout: checkoutDefaults
}

class Checkout extends PureComponent {
  formatMessage = this.props.intl.formatMessage

  // handle cases for late checkout text, and provide i18n text.
  getLateCheckout = () => {
    // if this value is false, then host selected "Don't mention late checkouts"
    if (this.props.checkout.show_late_checkout) {
      if (this.props.checkout.late_checkout_contact) {
        return this.formatMessage(checkoutMessages.lateCheckoutContact)
      } else if (this.props.checkout.late_checkout_sometimes) {
        return this.formatMessage(checkoutMessages.lateCheckoutSometimes)
      } else if (this.props.checkout.late_checkout_no) {
        return this.formatMessage(checkoutMessages.lateCheckoutNo)
      }
    }
    return ''
  }

  // gets the relevant checkout items
  // types: ['checkout', 'cleanup']
  // - 'checkout' items appear above the divider
  // - 'cleanup' items show below the divider as 'before leaving' items
  getCheckoutItems = (type) => {
    var itemsToRender = []
    if (typeof checkoutItems[type] !== 'undefined') {
      var self = this
      for (var i = 0, len = checkoutItems[type].length; i < len; i++) {
        var checkoutItemId = checkoutItems[type][i]
        if (
          self.props.checkout[checkoutItemId] !== 'undefined' &&
          self.props.checkout[checkoutItemId]
        ) {
          itemsToRender.push(self.renderCheckoutItem(checkoutItemId))
        }
      }
    }
    return itemsToRender
  }

  getCustomCheckoutItems = () => {
    const { checkout } = this.props
    if (
      checkout.custom_fields &&
      checkout.custom_fields.data &&
      checkout.custom_fields.data.length
    ) {
      const items = checkout.custom_fields.data
      const matchParams = this.context.router.route.match.params
      const deepLinkedCard = matchParams.card_title
        ? matchParams.card_title
        : null
      const modalAvatarStyle = this.props.custom_color
        ? { background: this.props.custom_color }
        : {}
      return items.map((item) => {
        const itemId = `custom_${stringToId(item.name)}_${randomString(3, '')}`
        const primaryHeading = translateString(
          this.props.intl.locale,
          item.name,
          item.name_txn
        )
        const secondaryText = translateString(
          this.props.intl.locale,
          item.instructions,
          item.instructions_txn
        )
        let showIcon = null
        if (item.icon) {
          let iconMatch = matchData(
            allIcons,
            'Icons',
            ['field', 'v1_name'],
            item.icon
          )
          if (iconMatch) {
            const IconComponent = iconMatch.icon
            showIcon = (
              <IconComponent width={24} height={24} fill="rgba(0,0,0,0.54)" />
            )
          } else {
            showIcon = <i className="material-symbols-rounded">{item.icon}</i>
          }
        }
        if (this.props.viewMode === 'print') {
          return (
            <li className="li-showdot" key={itemId}>
              {secondaryText}
            </li>
          )
        } else {
          let threeLines = secondaryText.length > 40
          return (
            <ListItemModal
              avatarStyle={modalAvatarStyle}
              baseLocation={`/${this.props.guidebook.key}/departure`}
              cardTitle={itemId}
              guidebook={this.props.guidebook}
              key={itemId}
              leftIcon={showIcon}
              modalVisible={deepLinkedCard === itemId}
              primaryText={primaryHeading}
              secondaryText={secondaryText}
              showMore={false}
              tabName="departure"
              threeLines={threeLines}
              trackLabel={itemId}
            />
          )
        }
        // console.log({itemId:itemId, itemName: itemName, itemInstructions: itemInstructions, icon: showIcon});
      })
    }
    return null
  }

  renderCheckoutItem = (checkoutItemId) => {
    let primaryHeading = ''
    const matchParams = this.context.router.route.match.params
    const deepLinkedCard = matchParams.card_title
      ? matchParams.card_title
      : null
    const modalAvatarStyle = this.props.custom_color
      ? { background: this.props.custom_color }
      : {}
    if (
      this.props.checkout[checkoutItemId] !== 'undefined' &&
      this.props.checkout[checkoutItemId] === true
    ) {
      if (typeof checkoutItemHeadings[checkoutItemId] !== 'undefined') {
        primaryHeading = this.formatMessage(
          checkoutMessages[checkoutItemHeadings[checkoutItemId]]
        )
      }
      const secondaryText = this.formatMessage(checkoutMessages[checkoutItemId])
      if (this.props.viewMode === 'print') {
        return (
          <li className="li-showdot" key={checkoutItemId}>
            {secondaryText}
          </li>
        )
      } else {
        return (
          <ListItemModal
            avatarStyle={modalAvatarStyle}
            baseLocation={`/${this.props.guidebook.key}/departure`}
            cardTitle={checkoutItemId}
            guidebook={this.props.guidebook}
            key={checkoutItemId}
            leftIcon={checkoutIcons[checkoutItemId]}
            modalVisible={deepLinkedCard === checkoutItemId}
            primaryText={primaryHeading}
            secondaryText={secondaryText}
            showMore={false}
            tabName="departure"
            trackLabel={checkoutItemId}
          />
        )
      }
    }
  }

  expanderClicked = (open) => {
    // OLD SEGMENT CODE
    // let eventData = {
    //   category: 'Guidebook'
    // }
    // trackEvent(open ? 'checkoutExpanderShown' : 'checkoutExpanderHidden', eventData);
  }

  renderCheckoutText = () => {
    const { guidebook } = this.props
    const token_data =
      guidebook.token && guidebook.token.data ? guidebook.token.data : {}
    const variableData = { token: token_data }
    if (this.props.checkout.checkout_text) {
      return (
        <div>
          <AsHtml
            text={this.props.checkout.checkout_text}
            translations={this.props.checkout.checkout_text_txn}
            variableData={variableData}
          />
        </div>
      )
    }
  }

  renderCheckoutTime = (checkOutDate) => {
    const { checkout } = this.props

    const timeHeader = this.formatMessage(checkoutMessages.checkout)
    let timeContent
    if (
      typeof checkout.checkout_time !== 'undefined' &&
      checkout.checkout_time !== ''
    ) {
      // console.log(this.props.checkout.checkout_time);
      timeContent = this.formatMessage(checkoutMessages.checkoutTime, {
        timeValue: checkout.checkout_time
      })
    } else {
      timeContent = this.formatMessage(checkoutMessages.unknownCheckoutTime)
    }

    const checkoutTimeHTMLFull = timeContent
      ? [
          <div key="checkoutTimeHTML">
            {checkOutDate ? <p className="md-body-1">{checkOutDate}</p> : null}
            {timeContent ? <p className="md-body-1">{timeContent}</p> : null}
          </div>
        ]
      : null // needs to be in an array for some reason

    const matchParams = this.context.router.route.match.params
    const deepLinkedCard = matchParams.card_title
      ? matchParams.card_title
      : null
    const modalAvatarStyle = this.props.custom_color
      ? { background: this.props.custom_color }
      : {}

    if (this.props.viewMode === 'print') {
      const printTimeHeader = checkOutDate ? checkOutDate : timeHeader
      return (
        <PrintSubsection header={printTimeHeader}>
          {timeContent}
        </PrintSubsection>
      )
    } else {
      return (
        <ListItemModal
          avatarStyle={modalAvatarStyle}
          baseLocation={`/${this.props.guidebook.key}/departure`}
          cardTitle="checkout-time"
          guidebook={this.props.guidebook}
          leftIcon={checkoutIcons.time}
          modalVisible={
            deepLinkedCard === 'checkout-time' ||
            deepLinkedCard === 'check-out-time'
          }
          primaryText={timeContent}
          secondaryText=" "
          showMore={false}
          tabName="departure"
          threelines
          title={timeHeader}
          trackLabel="checkout time"
        >
          {checkoutTimeHTMLFull}
        </ListItemModal>
      )
    }
  }

  renderCheckoutContent = () => {
    const { checkout, guidebook } = this.props

    const checkoutHeader = this.formatMessage(checkoutMessages.checkout)
    const checkoutContent = this.formatMessage(
      checkoutMessages.addtlCheckoutInfo
    )

    const isSecure = guidebook.is_secure
    let checkoutHTML = null
    let checkoutText = null
    const token_data =
      guidebook.token && guidebook.token.data ? guidebook.token.data : {}
    const variableData = { token: token_data }
    // console.log(checkout.checkout_text);
    if (
      checkout &&
      (fieldHasContent(checkout.checkout_text) ||
        fieldHasContent(checkout.checkout_text_secure))
    ) {
      if (
        isSecure &&
        checkout.checkout_has_secure_info &&
        checkout.checkout_text_secure
      ) {
        checkoutHTML = (
          <AsHtml
            text={checkout.checkout_text_secure}
            translations={checkout.checkout_text_secure_txn}
            variableData={variableData}
          />
        )
        checkoutText = translateString(
          this.props.intl.locale,
          checkout.checkout_text_secure,
          checkout.checkout_text_secure_txn
        )
        //strip out HTML for listText
        checkoutText = cleanUpTextOnly(checkoutText)
        checkoutText = transformCustomVariables(checkoutText, variableData)
      } else {
        checkoutHTML = (
          <AsHtml
            text={checkout.checkout_text}
            translations={checkout.checkout_text_txn}
            variableData={variableData}
          />
        )
        checkoutText = translateString(
          this.props.intl.locale,
          checkout.checkout_text,
          checkout.checkout_text_txn
        )
        //strip out HTML for listText
        checkoutText = cleanUpTextOnly(checkoutText)
        checkoutText = transformCustomVariables(checkoutText, variableData)
      }
    } else {
      return null
    }
    const checkoutHTMLFull = checkoutHTML
      ? [
          <div key="checkouthtml">
            {checkoutHTML ? <p className="md-body-1">{checkoutHTML}</p> : null}
          </div>
        ]
      : null // needs to be in an array for some reason

    const matchParams = this.context.router.route.match.params
    const deepLinkedCard = matchParams.card_title
      ? matchParams.card_title
      : null
    const modalAvatarStyle = this.props.custom_color
      ? { background: this.props.custom_color }
      : {}

    if (this.props.viewMode !== 'print') {
      return (
        <ListItemModal
          avatarStyle={modalAvatarStyle}
          baseLocation={`/${this.props.guidebook.key}/departure`}
          cardTitle="checkout"
          guidebook={this.props.guidebook}
          leftIcon={checkoutIcons.content}
          modalVisible={
            deepLinkedCard === 'check-out' || deepLinkedCard === 'checkout'
          }
          primaryText={checkoutContent}
          secondaryText={checkoutText}
          tabName="departure"
          title={checkoutHeader}
          threelines
          trackLabel="checkout information"
        >
          {checkoutHTMLFull}
        </ListItemModal>
      )
    }
  }

  renderLateCheckout = () => {
    const lateCheckoutHeader = this.formatMessage(checkoutMessages.lateCheckout)
    const lateCheckoutContent = this.getLateCheckout()

    const modalAvatarStyle = this.props.custom_color
      ? { background: this.props.custom_color }
      : {}
    let lateCheckoutItem = ''
    if (lateCheckoutContent) {
      if (this.props.viewMode === 'print') {
        lateCheckoutItem = (
          <PrintSubsection header={lateCheckoutHeader}>
            {lateCheckoutContent}
          </PrintSubsection>
        )
      } else {
        lateCheckoutItem = (
          <ListItemModal
            avatarStyle={modalAvatarStyle}
            guidebook={this.props.guidebook}
            leftIcon={checkoutIcons.late}
            primaryText={lateCheckoutHeader}
            secondaryText={lateCheckoutContent}
            showMore={false}
            tabName="departure"
            threeLines
            trackLabel="late checkout"
          />
        )
      }
    }
    return lateCheckoutItem
  }

  renderTopItems = () => {
    let topItems = this.getCheckoutItems('checkout')
    return topItems
  }

  renderCustomItems = () => {
    let customItems = this.getCustomCheckoutItems()
    return customItems
  }

  renderBottomItems = () => {
    let bottomItems = ''
    let cleanupItems = this.getCheckoutItems('cleanup')
    if (cleanupItems.length > 0) {
      if (this.props.viewMode === 'print') {
        bottomItems = <div>{cleanupItems}</div>
      } else {
        bottomItems = (
          <div>
            <Divider />
            <Subheader
              primaryText={this.formatMessage(checkoutMessages.beforeLeaving)}
            />
            {cleanupItems}
          </div>
        )
      }
    }
    return bottomItems
  }

  render() {
    const { guidebook } = this.props

    let subtitle = this.formatMessage(checkoutMessages.departure)
    let checkOutDate = ''
    if (
      guidebook.token &&
      guidebook.token.data &&
      guidebook.token.data.checkout_date
    ) {
      subtitle = checkOutDate =
        this.formatMessage(checkoutMessages.checkOutDate, {
          dateValue: guidebook.token.data.checkout_date
        }) + ' '
    }

    // decide whether or not to show the "before leaving section"
    let bottomCheckout = null
    let showCheckoutItems =
      this.getCheckoutItems('cleanup').length > 0 ||
      this.getCustomCheckoutItems() !== null ||
      this.getCheckoutItems('checkout').length > 0
    if (showCheckoutItems) {
      bottomCheckout = (
        <PrintSubsection
          header={this.formatMessage(checkoutMessages.beforeLeaving)}
        >
          {this.renderCustomItems()}
          {this.renderTopItems()}
          {this.renderBottomItems()}
        </PrintSubsection>
      )
    }

    let additionalCheckout = null
    let showAdditional = fieldHasContent(this.props.checkout.checkout_text)
    if (showAdditional) {
      additionalCheckout = (
        <PrintSubsection
          header={this.formatMessage(checkoutMessages.addtlCheckoutInfo)}
        >
          {this.renderCheckoutText()}
        </PrintSubsection>
      )
    }

    if (this.props.viewMode === 'print') {
      return (
        <div>
          {this.renderCheckoutTime(checkOutDate)}
          {this.renderCheckoutContent()}
          {this.renderLateCheckout()}
          {additionalCheckout}
          {bottomCheckout}
        </div>
      )
    } else {
      const avatarStyle = { background: this.props.custom_color || '#ff9800' }
      return (
        <Card
          className="md-cell md-cell--12 arrival arrival-card"
          defaultExpanded={atLeastPhablet}
          onExpanderClick={this.expanderClicked}
          raise
        >
          <CardTitle
            avatar={
              <Avatar
                icon={checkoutIcons.place}
                role="presentation"
                style={avatarStyle}
              />
            }
            title={this.formatMessage(checkoutMessages.checkout)}
            subtitle={subtitle}
          />
          <List>
            {this.renderCheckoutTime(checkOutDate)}
            {this.renderCheckoutContent()}
            {this.renderLateCheckout()}
            {this.renderCustomItems()}
            {this.renderTopItems()}
            {this.renderBottomItems()}
          </List>
        </Card>
      )
    }
  }
}

Checkout.propTypes = propTypes
Checkout.defaultProps = defaultProps
Checkout.contextTypes = {
  router: PropTypes.object
}

export default connect()(injectIntl(Checkout))
