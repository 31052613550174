import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { ChromePicker } from 'react-color'
import { Avatar, Button, DialogContainer } from 'react-md'

import { allIcons } from 'constants/UIIcons'
import { matchData } from 'utils/Data'

const propTypes = {
  name: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  color: PropTypes.string,
  onColorChange: PropTypes.func.isRequired
}
const defaultProps = {
  color: '#CCCCCC'
}

class CustomTabColor extends Component {
  constructor(props) {
    super(props)
    this.state = {
      chooserOpen: false,
      currentColor: props.color || '#CCCCCC'
    }
    this.handleClick = this.handleClick.bind(this)
  }

  componentWillUpdate(nextProps) {
    if (this.props.color !== nextProps.color) {
      this.setState({ currentColor: nextProps.color })
    }
  }

  handleClick = () => {
    this.setState({ chooserOpen: true })
  }

  handleClose = () => {
    this.setState({ chooserOpen: false })
  }

  handleSave = () => {
    this.setState({ chooserOpen: false }, () => {
      this.props.onColorChange(this.state.currentColor)
    })
  }

  handleCancel = () => {
    this.setState({ currentColor: this.props.color, chooserOpen: false })
  }

  handleColorChange = (color) => {
    if (color && color.hex) {
      this.setState({ currentColor: color.hex })
    }
  }

  render() {
    const { name, icon, color, onColorChange, ...rest } = this.props
    // use first letter of name if no icon
    let avatarIcon = null
    if (name) {
      avatarIcon = name.charAt(0).toUpperCase()
    }
    if (icon) {
      let iconMatch = matchData(allIcons, 'Icons', ['field', 'v1_name'], icon)
      if (iconMatch) {
        const IconComponent = iconMatch.icon
        avatarIcon = <IconComponent fill="#ffffff" />
      } else {
        avatarIcon = <i className="material-symbols-rounded">{icon}</i>
      }
    }
    const avatarStyle = { backgroundColor: this.state.currentColor }
    const cellStyle = { padding: '10px 0', cursor: 'pointer' }
    const divMargin = { marginTop: '8px' }
    return (
      <div
        className="md-cell--3 md-cell--2-tablet md-cell--2-phone md-text-center"
        style={cellStyle}
        onClick={this.handleClick}
        {...rest}
      >
        <div>
          <Avatar
            className={'hf-categorieslist-item'}
            style={avatarStyle}
            alt=""
          >
            {avatarIcon}
          </Avatar>
        </div>
        <div>{name.replace(/_/g, ' ').toUpperCase()}</div>
        <DialogContainer
          id={`colorDialog-${name}`}
          visible={this.state.chooserOpen}
          title={
            <div>
              <div>
                <Avatar
                  className={'hf-categorieslist-item'}
                  style={avatarStyle}
                  alt=""
                >
                  {avatarIcon}
                </Avatar>
              </div>
              <div>{name.replace(/_/g, ' ').toUpperCase()}</div>
            </div>
          }
          onHide={this.handleClose}
          portal={true}
          lastChild={true}
          disableScrollLocking={true}
          focusOnMount={false}
          className="md-text-center"
        >
          <ChromePicker
            color={this.state.currentColor}
            onChangeComplete={this.handleColorChange}
            disableAlpha
          />
          <div style={divMargin}></div>
          <div style={divMargin}>
            <Button onClick={this.handleSave} raised primary>
              OK
            </Button>
            &nbsp;&nbsp;&nbsp;
            <Button onClick={this.handleCancel} flat secondary>
              Cancel
            </Button>
          </div>
        </DialogContainer>
      </div>
    )
  }
}

CustomTabColor.propTypes = propTypes
CustomTabColor.defaultProps = defaultProps

export default CustomTabColor
