import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import { Avatar, Button, Card, CardActions, CardTitle, Media } from 'react-md'

import { genericMessages } from 'constants/UIConstants'
import { updateVideoUrl } from 'utils/Urls'

class VideoCard extends Component {
  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick = (e) => {
    this.props.clickCallBack(e)
  }

  render() {
    const { titleString, videoUrl, icon } = this.props
    const { formatMessage } = this.props.intl
    let showIcon = null
    if (icon?.icon) {
      const IconComponent = icon.icon
      showIcon = <IconComponent fill="#ffffff" />
    } else {
      showIcon = <i className="material-symbols-rounded">{icon}</i>
    }
    const avatarStyle = {
      background: this.props.custom_color,
      color: '#ffffff'
    }

    const videoCode = videoUrl ? (
      <Media aspectRatio="7-4">
        <iframe
          title={'Video - ' + titleString}
          src={updateVideoUrl(videoUrl)}
          frameBorder="0"
          seamless="seamless"
        />
      </Media>
    ) : null
    return (
      <Card
        key={'VideoCard' + titleString}
        raise={true}
        className="hf-clickable"
      >
        {videoCode}
        <CardTitle
          avatar={<Avatar icon={showIcon} alt="" style={avatarStyle} />}
          title={titleString}
          onClick={(e) => this.handleClick(e)}
        />
        <CardActions className="md-divider-border md-divider-border--top">
          <Button flat onClick={(e) => this.handleClick(e)}>
            {formatMessage(genericMessages.read_more)}
          </Button>
        </CardActions>
      </Card>
    )
  }
}

VideoCard.propTypes = {
  iconSuffix: PropTypes.string,
  titleString: PropTypes.string,
  videoUrl: PropTypes.string,
  icon: PropTypes.object,
  clickCallBack: PropTypes.func,
  custom_color: PropTypes.string
}

VideoCard.defaultProps = {
  custom_color: 'rgb(255, 87, 34)'
}

export default injectIntl(VideoCard)
