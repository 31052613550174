import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import { ListItem } from 'react-md'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'

import categoryNames from 'components/Categories/CategoryNames'
import {
  getAvailableTabs,
  updatePotentialTabs
} from 'components/NavDrawer/PotentialTabs'
import { labelMessages } from 'constants/UIConstants'
import { allIcons } from 'constants/UIIcons'
import { customColor } from 'utils/Color'
import { matchData } from 'utils/Data'
import { translateString } from 'utils/I18nHelper'
// import { trackEvent, getHostIdAndKey } from 'utils/Segment';
import { ucFirst } from 'utils/Strings'

const propTypes = {
  recsOnly: PropTypes.bool
}

const defaultProps = {
  recsOnly: false
}

class GuestNavItems extends Component {
  constructor(props) {
    super(props)
    this.potentialTabs = updatePotentialTabs(props.guidebook)
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.guidebook &&
      nextProps.guidebook &&
      this.props.guidebook.key !== nextProps.guidebook.key
    ) {
      this.potentialTabs = updatePotentialTabs(nextProps.guidebook)
    }
  }

  showEssentialsTab = (tab) => {
    this.trackTabClick(tab)
    this.props.dispatch(push(`${this.buildBaseGuidebookUrl()}/${tab}`))
  }
  showRecsTab = (tab) => {
    this.trackTabClick(tab)
    this.props.dispatch(
      push(`${this.buildBaseGuidebookUrl()}/recommendations/${tab}`)
    )
  }
  buildBaseGuidebookUrl = () => {
    const { recsOnly, guidebook } = this.props
    if (recsOnly && guidebook.recs_only_enabled && guidebook.recs_only_key) {
      return `/recs/${guidebook.recs_only_key}`
    } else {
      return `/${guidebook.key}`
    }
  }

  trackTabClick = (tab) => {
    // OLD SEGMENT CODE
    // let eventData = getHostIdAndKey(this.props.guidebook);
    // eventData.category = 'Guidebook';
    // eventData.label = tab;
    // trackEvent('leftnavClicked', eventData);
  }

  buildAvatar = (item, key, message) => {
    const name = message || item.name
    const { guidebook } = this.props
    const letterStyle = {
      width: '24px',
      textAlign: 'center',
      fontSize: '20px',
      lineHeight: '24px'
    }
    let avatarIcon = null
    if (name) {
      avatarIcon = <div style={letterStyle}>{name.charAt(0).toUpperCase()}</div>
    }
    if (item.icon) {
      let iconMatch = matchData(
        allIcons,
        'Icons',
        ['field', 'v1_name'],
        item.icon
      )
      if (iconMatch) {
        const IconComponent = iconMatch.icon
        const avatarColor = customColor(key, guidebook.theme, item.color)
        avatarIcon = <IconComponent fill={avatarColor} />
      } else {
        avatarIcon = <i className="material-symbols-rounded">{item.icon}</i>
      }
    }
    return avatarIcon
  }

  buildItems = () => {
    const self = this
    const { intl, guidebook, recsOnly } = this.props
    const { formatMessage } = intl
    const availableTabs = getAvailableTabs(this.potentialTabs, recsOnly)
    const navItems = []

    // hardcode default tab to 'house_manual' for HarveyHelp
    const guidebooksToInclude = ['glltkdz', 'ggwrcmq', 'HarveyHelp', 'IrmaHelp']
    const harveyOverride = guidebooksToInclude.indexOf(guidebook.key) !== -1

    for (var i = 0; i < availableTabs.length; i++) {
      const key = availableTabs[i]
      if (this.potentialTabs[key].type === 'essentials') {
        let msg = formatMessage(labelMessages[key])
        if (key === 'house_manual' && harveyOverride) {
          msg = 'Helpful Links'
        }
        if (
          key === 'house_manual' &&
          guidebook.theme &&
          guidebook.theme.home_label
        ) {
          msg =
            guidebook.theme.home_label_txn[intl.locale] ||
            guidebook.theme.home_label
        }
        if (
          key === 'marketplace' &&
          guidebook.theme &&
          guidebook.theme.mktpl_label
        ) {
          msg =
            guidebook.theme.mktpl_label_txn[intl.locale] ||
            guidebook.theme.mktpl_label
        }
        if (
          key === 'book_again' &&
          guidebook.theme &&
          guidebook.theme.book_again_label
        ) {
          msg =
            guidebook.theme.book_again_label_txn[intl.locale] ||
            guidebook.theme.book_again_label
        }
        if (key === 'ai' && guidebook.theme && guidebook.theme.ai_label) {
          msg =
            guidebook.theme.ai_label_txn[intl.locale] ||
            guidebook.theme.ai_label
        }

        const tabOptions = this.potentialTabs[key]
        const avatarIcon = this.buildAvatar(tabOptions, key, msg)
        navItems.push(
          <ListItem
            key={key}
            onClick={() => self.showEssentialsTab(key)}
            leftIcon={avatarIcon}
            primaryText={msg}
          />
        )
      } else {
        const tabOptions = this.potentialTabs[key]
        // construct and translate tab name
        // see if we have a legacy translation based on the category name
        let message = null
        if (key === 'all') {
          const messageId = categoryNames[key]
          message = formatMessage(messageId)
        } else {
          message = ucFirst(
            translateString(intl.locale, tabOptions.name, tabOptions.name_txn)
          )
        }

        // use first letter of name if no icon
        const avatarIcon = this.buildAvatar(tabOptions, key, message)
        const onClick =
          tabOptions.type === 'recommendations'
            ? () => self.showRecsTab(key)
            : () => self.showEssentialsTab(key)
        navItems.push(
          <ListItem
            key={key}
            onClick={onClick}
            leftIcon={avatarIcon}
            primaryText={message}
          />
        )
      }
    }
    return navItems
  }

  render() {
    const { guidebook } = this.props
    if (guidebook.key === 'loading') {
      return <div />
    }

    return <div>{this.buildItems()}</div>
  }
}

GuestNavItems.propTypes = propTypes
GuestNavItems.defaultProps = defaultProps

export default connect(null)(injectIntl(GuestNavItems))
