import Breakjs from 'breakjs'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import DocumentTitle from 'react-document-title'
import {
  Button,
  DialogContainer,
  FontIcon,
  Paper,
  Switch,
  TextField,
  Toolbar
} from 'react-md'
import { connect } from 'react-redux'
import { actions, Errors, Form } from 'react-redux-form'
import { push } from 'react-router-redux'

import CheckBoxForm from 'components/CheckBoxForm'
import IconChooser from 'components/IconChooser'
import InputTextField from 'components/InputTextField'
import LocaleForm from 'components/LocaleForm'
import MultilangWysiwyg from 'components/MultilangWysiwyg'
import TimePickerForm from 'components/TimePickerForm'
import TooltipButton from 'components/TooltipButton'
import TooltipFontIcon from 'components/TooltipFontIcon'
import VoiceForm from 'components/VoiceForm'
import {
  checkoutItems,
  checkoutMessages,
  hostUIMessages,
  localeMessages,
  prefixFields,
  tokenFields
} from 'constants/UIConstants'
import { allIcons } from 'constants/UIIcons'
import { copyItem, saveItem, showItems } from 'redux/modules/crud'
import blankEditStates from 'redux/modules/crud/blankEditStates'
import currentUser from 'utils/CurrentUser'
import { matchData } from 'utils/Data'
import { stringToId } from 'utils/Strings'

import FormCardActions from './FormCardActions'
import ManageSelectedGuidebooks from './ManageSelectedCards'
import ManageGuidebooksAndTemplates from './ManageSelectedCards/ManageGuidebooksAndTemplates'

const breakLayout = Breakjs({
  mobile: 0,
  phablet: 550,
  desktop: 1024,
  superwide: 1500
})

class CheckoutForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      earlyCheckShowText: false,
      customFieldFormVisible: false,
      customFieldEditIdx: null,
      confirmDeleteField: null,
      customFieldName: '',
      customFieldNameTranlations: {},
      customFieldInstructions: '',
      customFieldInstructionsTranslations: {},
      customFieldIcon: 'CheckBox',
      customFieldNameError: '',
      customFieldInstructionError: '',
      confirmDeleteVisible: false,
      customFieldTranslationsVisible: false
    }
    this.goBack = this.goBack.bind(this)
    this.copyCard = this.copyCard.bind(this)
    this.saveAndAddAnother = this.saveAndAddAnother.bind(this)
  }

  goBack = () => {
    const { dispatch, pluralName, guidebookId, ownerPluralName } = this.props
    if (guidebookId) {
      const path = '#checkouts#checkout'
      dispatch(push(`/host/${ownerPluralName}/${guidebookId}${path}`))
    } else if (window.isIframe) {
      window.history.back()
    } else {
      dispatch(showItems(pluralName))
    }
  }

  copyCard = (id) => {
    const { dispatch, pluralName } = this.props
    dispatch(copyItem(pluralName, id))
  }

  formatMessage = this.props.intl.formatMessage

  componentDidMount() {
    const { dispatch, itemId } = this.props
    if (itemId === 'create') {
      let newItem = blankEditStates.checkout.data
      // also if we're making a new one and our "owner" has locales, default to those
      if (this.props.owner_data && this.props.owner_data.locales.length > 0) {
        newItem = Object.assign({}, newItem, {
          locales: this.props.owner_data.locales
        })
      }
      dispatch(actions.load('edit_checkout', newItem))
    }
  }

  componentWillUnmount() {
    //clear redux state for next item
    this.props.dispatch(actions.reset('edit_checkout'))
  }

  handleSubmit(item) {
    const { itemId, dispatch, guidebookId, copy } = this.props
    const id = copy ? null : itemId
    const addAnother = this.addAnother
    this.addAnother = false
    const currentGuidebook = guidebookId ? this.props.owner_data : null
    dispatch(
      saveItem('checkouts', 'checkout', id, item, addAnother, currentGuidebook)
    )
  }

  handleCheckoutHasSecure = (value) => {
    const { dispatch } = this.props
    dispatch(actions.change('edit_checkout.checkout_has_secure_info', value))
  }

  formHasErrors = (formErrors) => {
    const edit_form = this.props.edit_form
    if (edit_form.$form.touched) {
      for (var field in formErrors) {
        if (edit_form[field] && !edit_form[field].valid) {
          return false
        }
      }
    }
    return true
  }

  saveAndAddAnother = (e) => {
    const { dispatch } = this.props
    this.addAnother = true
    dispatch(actions.submit('edit_checkout'))
  }

  renderCheckoutItems = (type) => {
    return checkoutItems[type].map((checkoutItem) => {
      return this.renderCheckoutItem(checkoutItem)
    })
  }

  renderCheckoutItem = (key) => {
    return (
      <CheckBoxForm
        key={key}
        model={'.' + key}
        name={key}
        id={key}
        label={this.formatMessage(checkoutMessages[key])}
      />
    )
  }

  saveCustomField = () => {
    const currentFields = this.props.edit_checkout.custom_fields
    const {
      customFieldName,
      customFieldNameTranslations,
      customFieldInstructions,
      customFieldInstructionsTranslations,
      customFieldIcon,
      customFieldEditIdx
    } = this.state
    if (customFieldInstructions.length > 0 && customFieldName.length > 0) {
      this.toggleCustomField()
      let allFields = []
      let nextOrder = 0
      if (currentFields && currentFields.data && currentFields.data.length) {
        nextOrder = currentFields.data.length
        allFields = Object.assign([], currentFields.data)
      }
      let customField = {
        name: customFieldName,
        name_txn: customFieldNameTranslations,
        instructions: customFieldInstructions,
        instructions_txn: customFieldInstructionsTranslations,
        icon: customFieldIcon
      }
      if (customFieldEditIdx !== null) {
        customField.order = customFieldEditIdx
        allFields[customFieldEditIdx] = customField
      } else {
        customField.order = nextOrder
        allFields.push(customField)
      }
      const newData = {
        data: allFields
      }
      this.setState(
        {
          customFieldName: '',
          customFieldNameTranslations: '',
          customFieldInstructions: '',
          customFieldInstructionsTranslations: {},
          customFieldEditIdx: null
        },
        () => {
          this.props.dispatch(
            actions.change('edit_checkout.custom_fields', newData)
          )
        }
      )
    } else {
      if (customFieldName.length === 0) {
        this.setState({
          customFieldNameError: 'please enter a checkout item name'
        })
      } else if (customFieldName.length > 40) {
        this.setState({
          customFieldNameError:
            'checkout item name must not exceed 40 characters.'
        })
      }
      if (customFieldInstructions.length === 0) {
        this.setState({
          customFieldInstructionError: 'please enter checkout instructions'
        })
      } else if (customFieldInstructions.length > 140) {
        this.setState({
          customFieldInstructionError:
            'checkout instructions must not exceed 140 characters.'
        })
      }
    }
  }
  deleteCustomField = () => {
    const self = this
    const currentFields = this.props.edit_checkout.custom_fields
    const { confirmDeleteField } = this.state
    const newFields = []
    for (var i = 0; i < currentFields.data.length; i++) {
      if (i < confirmDeleteField) {
        newFields.push(currentFields.data[i])
      } else if (i > confirmDeleteField) {
        const newField = Object.assign({}, currentFields.data[i])
        newField.order = i - 1
        newFields.push(newField)
      }
    }
    let newData = {}
    if (newFields.length) {
      newData.data = newFields
    }
    this.hideConfirmDeleteField(() => {
      self.props.dispatch(
        actions.change('edit_checkout.custom_fields', newData)
      )
    })
  }

  editCustomField = (idx) => {
    const self = this
    const itemToEdit = self.props.edit_checkout.custom_fields.data[idx]
    this.setState({
      customFieldName: itemToEdit.name,
      customFieldNameTranslations: itemToEdit.name_txn,
      customFieldInstructions: itemToEdit.instructions,
      customFieldInstructionsTranslations: itemToEdit.instructions_txn,
      customFieldIcon: itemToEdit.icon,
      customFieldEditIdx: idx,
      customFieldFormVisible: true
    })
  }

  reOrderField = (fieldIndex, direction) => {
    const self = this
    const newData = {
      data: []
    }
    let objMoved = null,
      objReplaced = null
    const currentFields = this.props.edit_checkout.custom_fields
    if (currentFields.data && currentFields.data.length) {
      for (var i = 0; i < currentFields.data.length; i++) {
        if (i === fieldIndex) {
          objMoved = Object.assign({}, currentFields.data[i])
          objMoved.order = i + direction
        } else if (i === fieldIndex + direction) {
          objReplaced = Object.assign({}, currentFields.data[i])
          objReplaced.order = i - direction
        } else {
          const newObj = Object.assign({}, currentFields.data[i])
          newData.data[i] = newObj
        }
      }
      newData.data[fieldIndex] = objReplaced
      newData.data[fieldIndex + direction] = objMoved
      self.props.dispatch(
        actions.change('edit_checkout.custom_fields', newData)
      )
    }
  }

  handleShowTranslations = () => {
    this.setState({
      customFieldTranslationsVisible: !this.state.customFieldTranslationsVisible
    })
  }

  toggleCustomField = (a) => {
    if (this.state.customFieldFormVisible) {
      // if closing, clear the fields
      this.setState({
        customFieldFormVisible: false,
        customFieldName: '',
        customFieldNameTranslations: {},
        customFieldInstructions: '',
        customFieldInstructionsTranslations: {},
        customFieldIcon: 'CheckBox',
        customFieldEditIdx: null
      })
    } else {
      this.setState({ customFieldFormVisible: true })
    }
  }
  handleNameChange = (value) => {
    let error = value.length
      ? value.length <= 40
        ? ''
        : 'please use 40 characters or fewer'
      : 'please enter a name'

    this.setState({ customFieldName: value, customFieldNameError: error })
  }
  handleNameTranslationChange = (locale, value) => {
    if (locale && typeof value !== 'undefined') {
      const updatedTranslations = Object.assign(
        {},
        this.state.customFieldNameTranslations
      )
      updatedTranslations[locale] = value
      this.setState({ customFieldNameTranslations: updatedTranslations })
    }
  }
  handleInstructionsChange = (value) => {
    const error = value.length
      ? value.length <= 140
        ? ''
        : 'please use 140 characters or fewer'
      : 'please enter instructions'
    this.setState({
      customFieldInstructions: value,
      customFieldInstructionError: error
    })
  }
  handleInstructionTranslationChange = (locale, value) => {
    if (locale && typeof value !== 'undefined') {
      const updatedTranslations = Object.assign(
        {},
        this.state.customFieldInstructionsTranslations
      )
      updatedTranslations[locale] = value
      this.setState({
        customFieldInstructionsTranslations: updatedTranslations
      })
    }
  }
  handlecustomFieldIconChange = (value) => {
    this.setState({ customFieldIcon: value })
  }
  confirmDeleteCustomField = (idx) => {
    this.setState({ confirmDeleteField: idx, confirmDeleteVisible: true })
  }
  hideConfirmDeleteField = (cb) => {
    this.setState(
      { confirmDeleteField: null, confirmDeleteVisible: false },
      () => {
        if (typeof cb === 'function') {
          cb()
        }
      }
    )
  }

  renderCurrentCustomFields = () => {
    const self = this
    const customFields = this.props.edit_checkout.custom_fields
    if (customFields && customFields.data && customFields.data.length) {
      const fieldRows = customFields.data.map((item, idx) => {
        const evenOdd = idx % 2 === 1 ? 'even' : 'odd'
        let showIcon = null
        if (item.icon) {
          let iconMatch = matchData(
            allIcons,
            'Icons',
            ['field', 'v1_name'],
            item.icon
          )
          if (iconMatch) {
            const IconComponent = iconMatch.icon
            showIcon = (
              <IconComponent width={24} height={24} fill="rgba(0,0,0,0.54)" />
            )
          } else {
            showIcon = <i className="material-symbols-rounded">{item.icon}</i>
          }
        }

        return [
          <div
            className={`md-cell md-cell--2 md-cell--1-tablet md-cell--2-phone row-${evenOdd}`}
            key={`cell-${stringToId(item.name)}-required`}
          >
            {showIcon}
          </div>,
          <div
            className={`md-cell md-cell--2 md-cell--1-tablet md-cell--2-phone row-${evenOdd}`}
            key={`cell-${stringToId(item.name)}-name`}
          >
            {item.name}
          </div>,
          <div
            className={`md-cell md-cell--4 md-cell--3-tablet md-cell--4-phone row-${evenOdd}`}
            key={`cell-${stringToId(item.name)}-instructions`}
          >
            {item.instructions}
          </div>,
          <div
            data-order={item.order}
            className={`md-cell md-cell--4 md-cell--3-tablet md-cell--4-phone md-text-right row-${evenOdd}`}
            key={`cell-${stringToId(item.name)}-spacer`}
          >
            {item.order > 0 ? (
              <TooltipFontIcon
                className="action-icon"
                tooltipLabel="Move Up"
                tooltipPosition="top"
                onClick={() => {
                  self.reOrderField(idx, -1)
                }}
              >
                arrow_upward
              </TooltipFontIcon>
            ) : (
              <FontIcon
                className="action-icon disabled"
                onClick={() => {
                  /* nada */
                }}
              >
                arrow_upward
              </FontIcon>
            )}
            {item.order < customFields.data.length - 1 ? (
              <TooltipFontIcon
                className="action-icon"
                tooltipLabel="Move Down"
                tooltipPosition="top"
                onClick={() => {
                  self.reOrderField(idx, 1)
                }}
              >
                arrow_downward
              </TooltipFontIcon>
            ) : (
              <FontIcon
                className="action-icon disabled"
                onClick={() => {
                  /* nada */
                }}
              >
                arrow_downward
              </FontIcon>
            )}
            <TooltipFontIcon
              className="action-icon"
              tooltipLabel="Edit"
              tooltipPosition="top"
              onClick={() => {
                self.editCustomField(idx)
              }}
            >
              edit
            </TooltipFontIcon>
            <TooltipFontIcon
              className="action-icon"
              tooltipLabel="Delete Custom Field"
              tooltipPosition="top"
              onClick={() => {
                self.confirmDeleteCustomField(idx)
              }}
            >
              delete
            </TooltipFontIcon>
          </div>
        ]
      })
      return (
        <div className="md-cell md-cell--12 splash-field-data">
          <div className="md-grid md-grid--no-spacing">
            <div className="md-cell md-cell--2 md-cell--1-tablet md-cell--2-phone row-header">
              <strong>Icon</strong>
            </div>
            <div className="md-cell md-cell--2 md-cell--1-tablet md-cell--2-phone row-header">
              <strong>Field Name</strong>
            </div>
            <div className="md-cell md-cell--4 md-cell--3-tablet md-cell--phone-hidden row-header">
              <strong>Instructions</strong>
            </div>
            <div className="md-cell md-cell--4 md-cell--3-tablet md-cell--1-phone row-header" />
            {fieldRows}
          </div>
        </div>
      )
    } else {
      return (
        <div className="md-cell md-cell--12">
          You have not defined any custom checkout items.
        </div>
      )
    }
  }

  renderTranslationFields = () => {
    const self = this
    const visible = this.state.customFieldTranslationsVisible
    const locales = this.props.edit_checkout.locales
    if (visible) {
      const nameTranslationFields = locales.map((locale) => {
        const fieldValue =
          self.state.customFieldNameTranslations &&
          self.state.customFieldNameTranslations.hasOwnProperty(locale)
            ? self.state.customFieldNameTranslations[locale]
            : ''
        return (
          <TextField
            id={`customFieldNameTxn_${locale}`}
            name={`customFieldNameTxn_${locale}`}
            key={`customFieldNameTxn_${locale}`}
            label={`Name (${self.formatMessage(localeMessages[locale])})`}
            value={fieldValue}
            onChange={(value) => {
              self.handleNameTranslationChange(locale, value)
            }}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault()
                e.stopPropagation()
              }
            }}
          />
        )
      })
      const instructionTranslationFields = locales.map((locale) => {
        const fieldValue =
          self.state.customFieldInstructionsTranslations &&
          self.state.customFieldInstructionsTranslations.hasOwnProperty(locale)
            ? self.state.customFieldInstructionsTranslations[locale]
            : ''
        return (
          <TextField
            id={`customFieldInstructionTxn_${locale}`}
            name={`customFieldInstructionTxn_${locale}`}
            key={`customFieldInstructionTxn_${locale}`}
            label={`Instructions (${self.formatMessage(localeMessages[locale])})`}
            value={fieldValue}
            onChange={(value) => {
              self.handleInstructionTranslationChange(locale, value)
            }}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault()
                e.stopPropagation()
              }
            }}
          />
        )
      })
      return (
        <div className="md-cell md-cell--12">
          <div>
            {nameTranslationFields}
            {instructionTranslationFields}
          </div>
          <Button flat primary onClick={self.handleShowTranslations}>
            {self.state.customFieldTranslationsVisible
              ? 'Hide Translations'
              : 'Edit Translations'}
          </Button>
        </div>
      )
    } else if (locales.length > 0) {
      return (
        <div className="md-cell md-cell--12">
          <Button flat primary onClick={self.handleShowTranslations}>
            {self.state.customFieldTranslationsVisible
              ? 'Hide Translations'
              : 'Edit Translations'}
          </Button>
        </div>
      )
    }
    return null
  }

  renderCustomFields = () => {
    const self = this
    // if free user, no custom fields
    const user = currentUser()
    if (!user.isPaidHost) return null

    const topMargin = { marginTop: '12px' }
    const addFieldActions = [
      <Button
        key="btn-save-field"
        raised
        primary
        onClick={self.saveCustomField}
      >
        Save
      </Button>,
      <Button
        key="btn-cancel-field"
        raised
        secondary
        onClick={self.toggleCustomField}
      >
        Cancel
      </Button>
    ]

    const layout = breakLayout.current()
    const addFieldDialog = (
      <DialogContainer
        id="addFieldDialog"
        visible={self.state.customFieldFormVisible}
        actions={addFieldActions}
        onHide={self.toggleCustomField}
        title={
          self.state.customFieldEditIdx
            ? 'Edit checkout item'
            : 'Add a new checkout item'
        }
        width={layout === 'mobile' ? '90vw' : 500}
      >
        <div className="md-grid">
          <TextField
            id="customFieldName"
            name="customFieldName"
            label="Checkout Item Name"
            className="md-cell md-cell--12"
            value={self.state.customFieldName}
            onChange={(value) => {
              self.handleNameChange(value)
            }}
            maxLength={40}
            error={self.state.customFieldNameError ? true : false}
            errorText={self.state.customFieldNameError}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault()
                e.stopPropagation()
                return self.saveCustomField()
              }
            }}
          />
          <TextField
            id="customFieldInstructions"
            name="customFieldInstructions"
            label="Checkout Item Instructions"
            className="md-cell md-cell--12"
            value={self.state.customFieldInstructions}
            onChange={(value) => {
              self.handleInstructionsChange(value)
            }}
            maxLength={140}
            error={self.state.customFieldInstructionError ? true : false}
            errorText={self.state.customFieldInstructionError}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault()
                e.stopPropagation()
                return self.saveCustomField()
              }
            }}
          />
          {self.renderTranslationFields()}
          <div className="md-cell md-cell--12">
            <IconChooser
              initialValue={self.state.customFieldIcon}
              ignoreModel={true}
              useAvatar={false}
              color="rgba(0,0,0,0.54)"
              onChange={(value) => {
                self.handlecustomFieldIconChange(value)
              }}
            />
          </div>
        </div>
      </DialogContainer>
    )

    const confirmDeleteActions = [
      <Button
        key="btn-save-field"
        raised
        primary
        onClick={self.deleteCustomField}
      >
        Yes, Delete it.
      </Button>,
      <Button
        key="btn-cancel-field"
        raised
        secondary
        onClick={self.hideConfirmDeleteField}
      >
        Cancel
      </Button>
    ]

    const deleteFieldDialog = (
      <DialogContainer
        id="confirmDeleteField"
        visible={this.state.confirmDeleteVisible}
        actions={confirmDeleteActions}
        onHide={this.hideConfirmDeleteField}
        title="Delete Custom Field?"
      >
        Are you sure you wish to delete this custom checkout item?
      </DialogContainer>
    )

    return (
      <div className="md-grid md-grid--no-spacing" style={topMargin}>
        <div className="md-cell md-cell--12">
          <h3>Custom Checkout Items</h3>
        </div>
        {this.renderCurrentCustomFields()}
        {addFieldDialog}
        {deleteFieldDialog}
        <div className="md-cell md-cell--12" style={topMargin}>
          <Button raised primary onClick={this.toggleCustomField}>
            Add a checkout item
          </Button>
        </div>
      </div>
    )
  }

  render() {
    const self = this
    const { edit_checkout, itemId, copy, guidebookId, ownerPluralName } =
      this.props
    const locales = edit_checkout.locales
    const title = copy
      ? 'Copy checkout card'
      : itemId === 'create'
        ? 'New checkout card'
        : 'Edit checkout card'
    const nav = (
      <TooltipButton
        key="nav"
        onClick={self.goBack}
        tooltipLabel="Back"
        tooltipPosition="bottom"
        icon
      >
        <FontIcon>keyboard_backspace</FontIcon>
      </TooltipButton>
    )

    const actions = []
    const formErrors = []
    const errorMessages = {
      formwide: {
        valid: 'Oops! Unable to save:'
      },
      label: {
        required: 'Please provide a label'
      },
      checkout_time: {
        required: 'Please enter a checkout time'
      }
    }
    formErrors['formwide'] = (
      <Errors
        model="edit_checkout"
        messages={errorMessages.formwide}
        wrapper="h2"
        className="md-text-field-message title hf-error"
        show={(form) => form.submitFailed}
      />
    )
    formErrors['label'] = (
      <Errors
        model=".label"
        messages={errorMessages.label}
        className="md-text-field-message hf-error"
        show={(field) => field.touched && !field.focus}
      />
    )
    formErrors['checkout_time'] = (
      <Errors
        model=".checkout_time"
        messages={errorMessages.checkout_time}
        className="md-text-field-message hf-error"
        show={(field) => field.touched && !field.focus}
      />
    )

    const user = currentUser()
    if (edit_checkout.id && user.canCopy && !copy && !guidebookId) {
      actions.push(
        <TooltipButton
          key="copy"
          onClick={() => {
            self.copyCard(edit_checkout.id)
          }}
          tooltipLabel="Copy"
          tooltipPosition="bottom"
          icon
        >
          <FontIcon>content_copy</FontIcon>
        </TooltipButton>
      )
    }

    const validators = {
      '': {
        // Form-level validator
        valid: () => this.formHasErrors(formErrors)
      },
      label: {
        required: (val) => val && val.length
      },
      checkout_time: {
        required: (val) => val && val.length
      }
    }

    const availableVariables = user.canUseSecurity
      ? prefixFields(tokenFields, 'token')
      : []
    const accessPrompt =
      user.canUseSecurity && edit_checkout.checkout_has_secure_info
        ? 'Enter your public information here:'
        : 'Additional checkout information'

    return (
      <DocumentTitle title="Host Checkout">
        <div className="hf-checkouts-paper">
          <Toolbar
            colored
            className="hf-edit-toolbar hf-checkouts"
            title={title}
            nav={nav}
            actions={actions}
          />
          <Paper key="category" className="hf-form-wrapper">
            <Form
              model="edit_checkout"
              onSubmit={(v) => this.handleSubmit(v)}
              validators={validators}
            >
              <div className="md-grid">
                <div className="md-cell md-cell--12 hf-headline-margin">
                  <h2 className="md-headline">
                    {this.formatMessage(hostUIMessages['nameHeading'])}
                  </h2>
                  <InputTextField
                    model=".label"
                    id="label"
                    label="Card name *"
                  />
                  {formErrors['label']}
                </div>
                <div className="md-cell md-cell--12 hf-headline-margin">
                  <h2 className="md-headline">
                    {this.formatMessage(hostUIMessages['languageHeading'])}
                  </h2>
                  <p className="md-body-1">
                    {this.formatMessage(hostUIMessages['languageSubHeading'])}
                  </p>
                  <LocaleForm editModel="edit_checkout" />
                </div>
                <div className="md-cell md-cell--12 hf-headline-margin">
                  <h2 className="md-headline">
                    What time do you want to tell guests to check out by?
                  </h2>
                  <TimePickerForm
                    editModel="edit_checkout"
                    model="checkout_time"
                    value={this.props.edit_checkout.checkout_time}
                    defaultValue="10:00 AM"
                    id="checkout_time"
                    label="Checkout time *"
                  />
                  {formErrors['checkout_time']}
                </div>
                <div className="md-cell md-cell--12 hf-headline-margin">
                  <h2 className="md-headline">
                    Select the statements that best describe your late checkout
                    policy
                  </h2>
                  <CheckBoxForm
                    model={'.late_checkout_no'}
                    name={'late_checkout_no'}
                    id={'late_checkout_no'}
                    label={this.formatMessage(
                      checkoutMessages['lateCheckoutNo']
                    )}
                  />
                  <CheckBoxForm
                    model={'.late_checkout_sometimes'}
                    name={'late_checkout_sometimes'}
                    id={'late_checkout_sometimes'}
                    label={this.formatMessage(
                      checkoutMessages['lateCheckoutSometimes']
                    )}
                  />
                  <CheckBoxForm
                    model={'.late_checkout_contact'}
                    name={'late_checkout_contact'}
                    id={'late_checkout_contact'}
                    label={this.formatMessage(
                      checkoutMessages['lateCheckoutContact']
                    )}
                  />
                  <CheckBoxForm
                    model={'.show_late_checkout'}
                    name={'show_late_checkout'}
                    id={'show_late_checkout'}
                    label={this.formatMessage(
                      checkoutMessages['showLateCheckout']
                    )}
                  />
                </div>
                <div className="md-cell md-cell--12 hf-headline-margin">
                  <h2 className="md-headline">
                    What should guests do before checking out?
                  </h2>
                  {this.renderCheckoutItems('checkout')}
                  {this.renderCheckoutItems('cleanup')}
                  {this.renderCustomFields()}
                </div>
                {user.canUseSecurity ? (
                  <div className="md-cell md-cell--12 hf-headline-margin">
                    <h2 className="md-headline">
                      Do you want to include restricted checkout details?
                    </h2>
                    <p>
                      If you choose yes, you can define a separate set of
                      instructions for guests who are viewing the guidebook with
                      a secure link.
                    </p>
                    <div className="md-grid md-grid--no-spacing">
                      <div className="md-cell md-cell--1 md-cell--1-phone">
                        <p className="md-text md-text-right hf-switch-left">
                          No
                        </p>
                      </div>
                      <div className="md-cell md-cell--11 md-cell--7-tablet md-cell--3-phone">
                        <Switch
                          id="checkout_has_secure_info"
                          name="checkout_has_secure_info"
                          checked={edit_checkout.checkout_has_secure_info}
                          onChange={this.handleCheckoutHasSecure}
                          label="Yes"
                        />
                      </div>
                    </div>
                  </div>
                ) : null}
                {user.canUseSecurity &&
                edit_checkout.checkout_has_secure_info ? (
                  <div className="md-cell md-cell--12 hf-headline-margin">
                    <h2 className="md-headline">
                      Enter your restricted information here:
                    </h2>
                    <p className="md-body-1">
                      Guests viewing your guidebook with a secure link will see
                      this information, while guests viewing your guidebook with
                      the normal link will see the public information (next
                      section below this one). &nbsp;
                      <a
                        className="hf-grey-link"
                        target="_blank"
                        href="/host/tokens"
                      >
                        You can create and edit secure links here.
                      </a>
                    </p>
                    <MultilangWysiwyg
                      model="edit_checkout"
                      field="checkout_text_secure"
                      txn_field="checkout_text_secure_txn"
                      locales={locales}
                      availableVariables={availableVariables}
                    />
                  </div>
                ) : null}
                <div className="md-cell md-cell--12 hf-headline-margin">
                  <h2 className="md-headline">{accessPrompt}</h2>
                  <MultilangWysiwyg
                    model="edit_checkout"
                    field="checkout_text"
                    txn_field="checkout_text_txn"
                    locales={locales}
                    availableVariables={availableVariables}
                  />
                </div>
              </div>
              <VoiceForm editModel="edit_checkout" locales={locales} />
              <div className="md-grid">
                <div className="md-cell md-cell--12 hf-headline-margin">
                  {user.isEnterprise ? (
                    <ManageGuidebooksAndTemplates
                      ownerPluralName="checkouts"
                      ownerSingularName="checkout"
                      guidebookId={guidebookId}
                      cardType="Checkout"
                      activeTab={ownerPluralName}
                      replaceWarning={true}
                      active={true}
                    />
                  ) : (
                    <ManageSelectedGuidebooks
                      ownerPluralName="checkouts"
                      ownerSingularName="checkout"
                      guidebookId={guidebookId}
                      cardType="Checkout"
                      replaceWarning={true}
                      active={true}
                    />
                  )}
                </div>
              </div>
              <div className="md-grid hf-headline-margin">
                <div className="md-cell md-cell--12">
                  <div>{formErrors['formwide']}</div>
                  <div>{formErrors['label']}</div>
                  <div>{formErrors['checkout_time']}</div>
                </div>
              </div>
              <FormCardActions
                guidebookId={guidebookId}
                saveAndAdd={this.saveAndAddAnother}
              />
            </Form>
          </Paper>
        </div>
      </DocumentTitle>
    )
  }
}

CheckoutForm.propTypes = {
  ownerSingularName: PropTypes.string,
  ownerPluralName: PropTypes.string
}

function mapStateToProps(state, props) {
  const { ownerSingularName } = props
  const edit_checkout = state.edit_checkout
  const edit_form = state.forms.edit_checkout
  const owner_data = state[`edit_${ownerSingularName}`]
  return {
    edit_checkout,
    owner_data,
    edit_form
  }
}

export default connect(mapStateToProps)(CheckoutForm)
