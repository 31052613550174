import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Avatar, Card, CardTitle, Media } from 'react-md'

class PhotoCard extends Component {
  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick = (e) => {
    this.props.clickCallBack(e)
  }

  render() {
    const { titleString, imageUrl, icon } = this.props
    let showIcon = null
    if (icon?.icon) {
      const IconComponent = icon.icon
      showIcon = <IconComponent fill="#ffffff" />
    } else {
      showIcon = <i className="material-symbols-rounded">{icon}</i>
    }
    const imgMedia = imageUrl ? (
      <Media aspectRatio="7-4" onClick={(e) => this.handleClick(e)}>
        <img src={imageUrl} alt="" />
      </Media>
    ) : null
    const avatarStyle = {
      background: this.props.custom_color,
      color: '#ffffff'
    }

    return (
      <Card
        key={'PhotoCard' + titleString}
        raise={true}
        className="hf-clickable"
        onClick={(e) => this.handleClick(e)}
      >
        {imgMedia}
        <CardTitle
          avatar={<Avatar icon={showIcon} alt="" style={avatarStyle} />}
          title={titleString}
        />
      </Card>
    )
  }
}

PhotoCard.propTypes = {
  iconSuffix: PropTypes.string,
  titleString: PropTypes.string,
  imageUrl: PropTypes.string,
  icon: PropTypes.object,
  clickCallBack: PropTypes.func,
  custom_color: PropTypes.string
}

PhotoCard.defaultProps = {
  custom_color: 'rgb(255, 87, 34)'
}

export default PhotoCard
