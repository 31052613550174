import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Avatar, FontIcon, ListItem } from 'react-md'
import { connect } from 'react-redux'
import {
  arrayMove,
  SortableContainer,
  SortableElement
} from 'react-sortable-hoc'

import { allIcons } from 'constants/UIIcons'
import { fetchItems } from 'redux/modules/crud'
import { matchData, sortData } from 'utils/Data'

const propTypes = {
  defaultOrder: PropTypes.object,
  onOrderChange: PropTypes.func
}
const defaultProps = {
  defaultOrder: {},
  onOrderChange: () => {}
}

class TabOrder extends Component {
  constructor(props) {
    super(props)
    this.state = {
      orderedTabs: []
    }
    this.defaultTabs = [
      {
        key: 'arrival',
        name: 'Arrival',
        type: 'essentials',
        icon: 'VpnKey',
        color: '#f44336',
        order: 0
      },
      {
        key: 'house_manual',
        name: 'House Manual',
        type: 'essentials',
        icon: 'Book',
        color: '#ff5722',
        order: 1
      },
      {
        key: 'departure',
        name: 'Departure',
        type: 'essentials',
        icon: 'Assignment',
        color: '#ff9800',
        order: 2
      },
      {
        key: 'ai',
        name: 'AI Planner',
        type: 'essentials',
        icon: 'Power',
        color: '#74aa9c',
        order: 3
      },
      {
        key: 'marketplace',
        name: 'Marketplace',
        type: 'essentials',
        icon: 'ShoppingCart',
        color: '#ffc107',
        order: 4
      },
      {
        key: 'book_again',
        name: 'Book Again',
        type: 'essentials',
        icon: 'LocationOn',
        color: '#573c0a',
        order: 5
      }
    ]
  }

  componentWillUnmount() {
    this.mounted = false
  }
  async componentDidMount() {
    this.mounted = true
    const { dispatch } = this.props
    dispatch(
      fetchItems('categories', () => {
        if (this.mounted) {
          const { categories, defaultOrder } = this.props
          // compile list of all the tabs, and put them in the current order if we have one
          let allTabs = Object.assign([], this.defaultTabs)
          let nextIdx = allTabs.length
          const sortedCategories = sortData(categories.data, 'order')
          for (var i = 0, len = sortedCategories.length; i < len; i++) {
            const cat_name = sortedCategories[i].name
            const cat_type = sortedCategories[i].type
            const cat_key = cat_name.toLowerCase().replace(/ /g, '_')
            const cat_label =
              cat_name.charAt(0).toUpperCase() + cat_name.slice(1).toLowerCase()
            allTabs.push({
              key: cat_key,
              name: cat_label,
              type: cat_type,
              order: nextIdx + i,
              icon: sortedCategories[i].icon,
              color: sortedCategories[i].color
            })
          }
          allTabs.push({
            key: 'all',
            name: 'All',
            type: 'recommendation',
            order: nextIdx + categories.data.length,
            icon: 'Apps',
            color: '#e91e63'
          })
          // if we have a default order, enter those, and then sort
          if (Object.keys(defaultOrder).length) {
            for (var j = 0, jLen = allTabs.length; j < jLen; j++) {
              if (defaultOrder.hasOwnProperty(allTabs[j].key)) {
                allTabs[j].order = defaultOrder[allTabs[j].key]
              }
            }
          }
          this.setState({ orderedTabs: sortData(allTabs, 'order') })
        }
      })
    )
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { onOrderChange } = this.props
    const { orderedTabs } = this.state
    const reOrderedTabs = arrayMove(orderedTabs, oldIndex, newIndex)
    this.setState({ orderedTabs: reOrderedTabs }, function () {
      // create a single object with the keys and the order
      let tabOrder = {}
      for (var i = 0, len = reOrderedTabs.length; i < len; i++) {
        tabOrder[reOrderedTabs[i].key] = i
      }
      onOrderChange(tabOrder)
    })
  }

  renderTabSorter = () => {
    const SortableItem = SortableElement(({ tab }) => {
      let avatarIcon = null
      let iconMatch = matchData(
        allIcons,
        'Icons',
        ['field', 'v1_name'],
        tab.icon
      )
      if (iconMatch) {
        const IconComponent = iconMatch.icon
        avatarIcon = <IconComponent fill="#ffffff" />
      } else {
        avatarIcon = <i className="material-symbols-rounded">{tab.icon}</i>
      }
      const tab_color = tab.color || '#cccccc'
      const avatarStyle = {
        backgroundColor: tab_color,
        height: '40px',
        width: '40px'
      }

      return (
        <ListItem
          key={tab.id}
          className="hf-list-item hf-categorieslist-item"
          leftAvatar={
            <Avatar
              className="hf-categorieslist-item"
              style={avatarStyle}
              alt=""
            >
              <FontIcon>{avatarIcon}</FontIcon>
            </Avatar>
          }
          primaryText={tab.name}
          rightIcon={<FontIcon>reorder</FontIcon>}
        />
      )
    })

    const SortableList = SortableContainer(({ tabs }) => {
      return (
        <div>
          {tabs.map((tab, index) => (
            <SortableItem key={`item-${index}`} index={index} tab={tab} />
          ))}
        </div>
      )
    })

    return (
      <div className="md-cell md-cell--12">
        <SortableList
          tabs={this.state.orderedTabs}
          onSortEnd={this.onSortEnd}
        />
      </div>
    )
  }

  renderLoading = () => {
    // TODO: flesh this out with loader and proper container and shit.
    return <div>Loading...</div>
  }

  renderTabs = () => {
    if (
      !this.props.categories ||
      !this.props.categories.loaded ||
      this.props.categories.data.length === 0
    ) {
      return this.renderLoading()
    } else {
      return this.renderTabSorter()
    }
  }

  render() {
    return (
      <div className="md-cell md-cell--12 hf-headline-margin">
        <h2 className="md-headline">Adjust the default tab order</h2>
        <p>
          Drag and Drop to arrange these tabs into the order you wish. This
          order will be reflected on any guidebook using this theme.
        </p>
        {this.renderTabs()}
      </div>
    )
  }
}

TabOrder.propTypes = propTypes
TabOrder.defaultProps = defaultProps

function mapStateToProps(state, props) {
  const { categories } = state.list
  return {
    categories: categories
  }
}

export default connect(mapStateToProps)(TabOrder)
