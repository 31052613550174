import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Avatar, DialogContainer, FontIcon, ListItem } from 'react-md'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { push } from 'react-router-redux'

import { allIcons } from 'constants/UIIcons'
import { convertToListings } from 'redux/modules/categories'
import { addToast } from 'redux/modules/toast'
import currentUser from 'utils/CurrentUser'
import { matchData } from 'utils/Data'

class CategoryListItem extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showConvertConfirm: false,
      confirmConvertId: null,
      confirmConvertName: null
    }
  }

  confirmConvertToListings = (item, e) => {
    e.preventDefault()
    e.stopPropagation()
    this.setState({
      showConvertConfirm: true,
      confirmConvertId: item.id,
      confirmConvertName: item.name
    })
  }

  closeConvertConfirm = (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.setState({
      showConvertConfirm: false,
      confirmConvertId: null,
      confirmConvertName: null
    })
  }

  convertItem = (e) => {
    const { dispatch } = this.props
    e.preventDefault()
    e.stopPropagation()
    convertToListings(this.state.confirmConvertId).then((data) => {
      dispatch(addToast('book again listings created'))
      dispatch(push('/host/listings'))
    })
  }

  renderConvertConfirmation = () => {
    return (
      <DialogContainer
        id="simpleDialogExample"
        aria-label="Confirm delete dialog"
        visible={this.state.showConvertConfirm}
        onHide={this.closeConvertConfirm}
        actions={[
          {
            onClick: this.closeConvertConfirm,
            primary: true,
            label: 'Cancel'
          },
          {
            onClick: this.convertItem,
            primary: true,
            label: 'Convert'
          }
        ]}
      >
        <p id="deleteItemDescription" className="md-color--secondary-text">
          Convert "{this.state.confirmConvertName}" category to Book Again
          Listings??
        </p>
      </DialogContainer>
    )
  }

  render() {
    const { item, editItem, deleteItem, pluralName } = this.props
    // use first letter of name if no icon
    let avatarIcon = null
    if (item.name) {
      avatarIcon = item.name.charAt(0).toUpperCase()
    }
    if (item.icon) {
      let iconMatch = matchData(
        allIcons,
        'Icons',
        ['field', 'v1_name'],
        item.icon
      )
      if (iconMatch) {
        const IconComponent = iconMatch.icon
        avatarIcon = <IconComponent fill="#ffffff" />
      } else {
        avatarIcon = <i className="material-symbols-rounded">{item.icon}</i>
      }
    }
    const avatarStyle = { backgroundColor: item.color || '#cccccc' }
    const user = currentUser()
    const typeSpan =
      item.type === 'recommendation' ? (
        <span className="cat-type-rec">recommendation</span>
      ) : (
        <span className="cat-type-hm">house manual</span>
      )
    const fullSecondary = (
      <div>
        <span>{item.description || ' '}</span>
        <br />
        {typeSpan}
      </div>
    )

    let categoryActions = [
      <FontIcon key={`cat-${item.id}-del`} onClick={deleteItem}>
        delete
      </FontIcon>
    ]
    if (!item.is_default && item.type === 'recommendation') {
      categoryActions.unshift(
        <FontIcon
          key={`cat-${item.id}-convert`}
          onClick={(e) => {
            this.confirmConvertToListings(item, e)
          }}
        >
          location_on
        </FontIcon>
      )
      categoryActions.push(
        <span key={`cat-${item.id}-dialog`}>
          {this.renderConvertConfirmation()}
        </span>
      )
    }

    const categoryItem =
      (user.isAdmin && user.id === 1055) || !item.is_default ? (
        <ListItem
          onClick={editItem}
          className={'hf-list-item hf-' + pluralName + 'list-item'}
          leftAvatar={
            <Link
              to={'/host/' + pluralName + '/' + item.id}
              onClick={(e) => {
                e.stopPropagation()
              }}
            >
              <Avatar
                className={'hf-' + pluralName + 'list-item'}
                style={avatarStyle}
                alt=""
              >
                {avatarIcon}
              </Avatar>
            </Link>
          }
          primaryText={
            <Link
              to={'/host/' + pluralName + '/' + item.id}
              onClick={(e) => {
                e.stopPropagation()
              }}
              className="md-tile-text--primary md-text hf-fake-link"
            >
              {item.name}
            </Link>
          }
          secondaryText={fullSecondary}
          rightIcon={categoryActions}
        />
      ) : (
        <ListItem
          className={'hf-list-item hf-' + pluralName + 'list-item'}
          leftAvatar={
            <Avatar
              className={'hf-' + pluralName + 'list-item'}
              style={avatarStyle}
              alt=""
            >
              {avatarIcon}
            </Avatar>
          }
          primaryText={item.name}
          secondaryText={fullSecondary}
          disabled={true}
          inkDisabled={true}
        />
      )

    return categoryItem
  }
}

CategoryListItem.propTypes = {
  item: PropTypes.object.isRequired
}
export default connect()(CategoryListItem)
