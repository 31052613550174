import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {
  Avatar,
  Button,
  Chip,
  DialogContainer,
  FontIcon,
  ListItem,
  TextField
} from 'react-md'
import { connect } from 'react-redux'
import { actions } from 'react-redux-form'

import TooltipButton from 'components/TooltipButton'
import { allIcons } from 'constants/UIIcons'
import { fuzzyFilter, matchData } from 'utils/Data'

/*TODO:  Once this bug has been fixed, remove the confirmation dialog from <Chip> which is a workaround for this issue:
 https://github.com/mlaursen/react-md/issues/582
 */
class IconChooser extends Component {
  constructor(props) {
    super(props)
    this.filter = this.filter.bind(this)
    this.clearFilter = this.clearFilter.bind(this)
    this.confirmRemoveSelectedIcon = this.confirmRemoveSelectedIcon.bind(this)
    this.removeSelectedIcon = this.removeSelectedIcon.bind(this)
    this.cancelRemoveSelectedIcon = this.cancelRemoveSelectedIcon.bind(this)
    this.selectIcon = this.selectIcon.bind(this)
    this.state = {
      filter: '',
      visible: false
    }
  }

  filter = (filter) => {
    this.setState({ filter })
  }

  clearFilter = () => {
    this.setState({ filter: '' })
  }

  selectIcon = (e) => {
    const icon = e.currentTarget.id
    if (this.props.ignoreModel && typeof this.props.onChange === 'function') {
      this.props.onChange(icon)
    } else {
      const { dispatch, editModel, editField } = this.props
      dispatch(actions.change(editModel + '.' + editField, icon))
    }
  }

  confirmRemoveSelectedIcon = () => {
    if (this.props.ignoreModel) {
      this.removeSelectedIcon()
    } else {
      this.setState({ visible: true })
    }
  }

  removeSelectedIcon = () => {
    this.setState({ visible: false })
    if (this.props.ignoreModel && typeof this.props.onChange === 'function') {
      this.props.onChange(null)
    } else {
      const { dispatch, editModel, editField } = this.props
      dispatch(actions.change(editModel + '.' + editField, null))
    }
  }

  cancelRemoveSelectedIcon = () => {
    this.setState({ visible: false })
  }

  render() {
    var self = this
    const { selectedIcon } = this.props
    const { visible } = this.state
    const actions = []
    actions.push(
      <Button flat secondary onClick={this.cancelRemoveSelectedIcon}>
        CANCEL
      </Button>
    )
    actions.push(
      <Button flat primary onClick={this.removeSelectedIcon}>
        OK
      </Button>
    )
    const selectedIconItem = selectedIcon
      ? matchData(allIcons, 'Icons', ['field', 'v1_name'], selectedIcon)
      : []
    const avatarStyle = { backgroundColor: self.props.color, border: 0 }
    let showIcon = null
    if (selectedIcon && selectedIcon.length > 0 && selectedIconItem) {
      const IconComponent = selectedIconItem.icon
      showIcon = (
        <IconComponent
          fill={this.props.useAvatar ? '#ffffff' : this.props.color}
        />
      )
    } else {
      showIcon = <i className="material-symbols-rounded">{selectedIcon}</i>
    }
    const IconTags = selectedIconItem ? selectedIconItem.tags : null
    const chip = selectedIcon ? (
      <Chip
        key="selectedIcon"
        label="Selected Icon"
        avatar={
          <Avatar
            icon={showIcon}
            className="hf-icon-chooser"
            style={avatarStyle}
          />
        }
        removable
        onClick={this.confirmRemoveSelectedIcon}
      />
    ) : null
    const filter = selectedIcon ? IconTags : this.state.filter
    const disabledHelpText = selectedIcon
      ? 'Clear the selected icon from above to edit the search / filter text'
      : null
    const enabledRightIcon = selectedIcon ? null : (
      <TooltipButton
        tooltipLabel="Clear Search / Filter"
        tooltipPosition="left"
        onClick={this.clearFilter}
        icon
      >
        <FontIcon>close</FontIcon>
      </TooltipButton>
    )
    const filterFields = ['field', 'tags']
    const filteredIcons = fuzzyFilter(allIcons, 'Icons', filterFields, filter)
    const finalIcons = filteredIcons.map(function (item) {
      const IconListItem = item.icon
      const thisIcon = (
        <IconListItem
          fill={self.props.useAvatar ? '#ffffff' : self.props.color}
        />
      )
      const thisAvatar = self.props.useAvatar ? (
        <Avatar
          icon={thisIcon}
          className="hf-icon-chooser"
          style={avatarStyle}
        />
      ) : (
        thisIcon
      )
      return (
        <ListItem
          leftAvatar={thisAvatar}
          key={item.field}
          id={item.field}
          onClick={self.selectIcon}
          className="md-cell md-cell--1"
          primaryText={item.field}
        />
      )
    })

    return (
      <div id="hf-icon-chooser" className="md-grid hf-headline-margin">
        <div key="filterPanel" className="md-cell md-cell--12 md-cell--bottom">
          {chip}
          <TextField
            key="filterField"
            id="filter"
            value={filter || ''}
            label="Search / Filter"
            type="text"
            leftIcon={<FontIcon>search</FontIcon>}
            rightIcon={enabledRightIcon}
            helpText={disabledHelpText}
            helpOnFocus={true}
            onChange={this.filter}
          />
        </div>
        {finalIcons}
        <DialogContainer
          id="confirmClearChipDialog"
          visible={visible}
          onHide={this.cancelRemoveSelectedIcon}
          actions={actions}
          portal={true}
          title="Remove current icon?"
        ></DialogContainer>
      </div>
    )
  }
}

IconChooser.propTypes = {
  editModel: PropTypes.string,
  editField: PropTypes.string,
  ignoreModel: PropTypes.bool,
  initialValue: PropTypes.string,
  useAvatar: PropTypes.bool,
  color: PropTypes.string
}

IconChooser.defaultProps = {
  initialValue: 'CheckBox',
  useAvatar: true,
  color: '#ff5722'
}

function mapStateToProps(state, props) {
  const { editModel, editField, ignoreModel, initialValue } = props
  const icon = ignoreModel ? initialValue : state[editModel][editField]
  return {
    selectedIcon: icon
  }
}

export default connect(mapStateToProps)(IconChooser)
